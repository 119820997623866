const TENANT_ID = process.env.REACT_APP_TENANT_ID;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const config = {
    clientId: CLIENT_ID, 
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: '/',
    PostLogoutRedirectUri: '/',
    scopes: [
        `api://${CLIENT_ID}/access_as_user`
    ],
    apiScopes: [
        `api://${CLIENT_ID}/.default`
    ]
};

// App registration: MyTestRegistration
// clientId: '9270fe7c-1b84-4ca6-895d-1017e5c49eb8',
// apiScopes: 'api://9270fe7c-1b84-4ca6-895d-1017e5c49eb8/.default'

// App registration: FANDS Keys
// clientId: 'cfec58be-09b9-44e9-be06-463660defafb',
// apiScopes: 'api:/cfec58be-09b9-44e9-be06-463660defafb/.default'

// App registration: DEV Keys
// clientId: '4a71465a-38cf-43d9-b5e1-6cb1794f030c'
// apiScopes: 'api:/4a71465a-38cf-43d9-b5e1-6cb1794f030c'

export default config
