import React from "react";
import { useMsalAuthentication, useIsAuthenticated } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import axiosInstance from "hooks/Common/axiosClient";
import config from "hooks/Common/config";
export const SendToken = (instance) => {
  const isAuthenticated = useIsAuthenticated();

  let accessTkn = React.useRef(); //using useRef so we dont keep updating value on state change

  //If user is not logged in, this with force auth request
  const { result, error, login } = useMsalAuthentication(
    InteractionType.Redirect,
    {
      scopes: config.scopes,
      claims: sessionStorage.getItem("claimsChallenge")
        ? window.atob(sessionStorage.getItem("claimsChallenge"))
        : undefined,
    }
  );

  //prepare accesstoken with successful auth
  React.useEffect(() => {
    if (result) {
      const { accessToken } = result;

      //setAccessTkn(accessToken)
      accessTkn.current = accessToken;
      //console.log(accessTkn)
    } else {
      if (instance && isAuthenticated) {
        instance
          .acquireTokenSilent({
            scopes: config.scopes,
            claims: sessionStorage.getItem("claimsChallenge")
              ? window.atob(sessionStorage.getItem("claimsChallenge"))
              : undefined,
          })
          .then((response) => {
            const accessToken = response.accessToken;
            //const act = instance.getActiveAccount();

            accessTkn.current = accessToken;
            //account.current = act;
          })
          .catch((error) => console.error(error));
      }
    }

    if (accessTkn.current) {
      const bearer = `Bearer ${accessTkn.current}`;
      axiosInstance.get("/api/verify", { headers: { Authorization: bearer } });
      console.log("what up dawg");
    }
  }, [isAuthenticated]);
};
