import React, { useState, useMemo, useRef } from "react";
//import axios from "axios";
import Button from "../../Common/Button/Button";
import Panel from "../../Common/Panel/Panel";
import Table from "../../Common/Table/Table";
import Input from "components/Common/Input/Input";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FullWidth, HalfWidth, CenterStyle } from "./KeyDetail.styles";
import { Modal } from "components/Common/Modal/Modal";
import LocalDetail from "../LocalDetails/LocalDetail";

import { getRoomGeneral, getRoomsAssignments } from "hooks/Rooms/roomGeneral";
import { execKeys, execKeysAssignments } from "hooks/Key/keyData";
import Notes from "components/Common/useNotes/useNotes";

import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext"; // May need to clear the global context cache

const KeyDetails = () => {
  const { setRoomCacheData } = useGlobalContext();
  const queryClient = useQueryClient();

  const inputRef = useMemo(
    () => Array.from({ length: 5 }, () => React.createRef()),
    []
  );

  const handleExternalReset = () => {
    changeDetail.current = {};

    inputRef.forEach((ref) => {
      ref?.current?.resetValue();
    });
  };

  const [action, setAction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [keyDetails, setKeyDetails] = useState(null);
  const [keyAssignments, setKeyAssignments] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [transactions, setTransactions] = useState([]);

  //custom values
  const [localDetail, setLocalDetail] = useState({
    inventory: 0,
    label: "",
    requestable: "",
    storageLocation: "",
    storageHook: "",
  });
  const changeDetail = useRef({});
  const keysWrite = useRef({});
  const keyAssignmentWrite = useRef({});

  const [localKeyData, setLocalKeyData] = useState([
    { value: 0, type: "int", label: "Inventory", item: "inventory" },
    { value: "", type: "text", label: "Label", item: "label" },
    {
      value: 1,
      type: "checkbox",
      label: "Requestable Online",
      item: "requestable",
    },
    {
      value: "",
      type: "text",
      label: "Storage Location",
      item: "storageLocation",
    },
    { value: "", type: "text", label: "Storage Hook", item: "storageHook" },
  ]);

  //change handler for local detail inputs
  const onLocalChange = (item, newValue) => {
    changeDetail.current[item] = newValue;
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const bldg = urlParams.get("bldg");
  const unitId = urlParams.get("unit");
  const keyCode = urlParams.get("keyid");

  const {
    isLoading: isLoadingLocal,
    isFetching: isFetchingLocal,
    isError: isErrorLocal,
  } = useQuery(
    ["fetchLocal"],
    () =>
      getRoomGeneral(
        "keyBoth", //Get both key details and the rooms
        "0",
        null,
        unitId,
        null, // we are not filtering on the buildingId so all buildings this key opens can be seen
        null,
        null,
        true,
        true,
        false, //Include all keys
        keyCode
      ),
    {
      onError: (error) => {
        console.log(isErrorLocal, error);
      },
      onSuccess: (data) => {
        mapLocalData(data);
      },
      fetchPolicy: "cache",
    }
  );

  let inventory = localDetail?.inventory ?? 0;
  inventory = inventory == "" ? 0 : inventory;

  let issued = localDetail?.issued ?? 0;
  issued = issued == "" ? 0 : issued;

  const mapLocalData = (data) => {
    const val1 = data?.data?.result1?.[0] ?? [];
    const val2 = data?.data?.result2 ?? [];

    if (val1 !== null) {
      setLocalDetail(val1);
    }
    setRooms(val2);

    setLocalKeyData([
      {
        value: val1?.inventory ?? 0,
        type: "int",
        label: "Inventory",
        item: "inventory",
        maxLength: 10,
      },
      {
        value: val1?.label ?? "",
        type: "text",
        label: "Label",
        item: "label",
        maxLength: 100,
      },
      {
        value: val1?.requestable ?? "1",
        type: "checkbox",
        label: "Requestable Online",
        item: "requestable",
      },
      {
        value: val1?.storageLocation ?? "",
        type: "text",
        label: "Storage Location",
        item: "storageLocation",
        maxLength: 1000, //Really why?
      },
      {
        value: val1?.storageHook ?? "",
        type: "text",
        label: "Storage Hook",
        item: "storageHook",
        maxLength: 50,
      },
    ]);
  };

  //-----getting unit/key info-----------------------------------------
  const optionsDate = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  const earliest = new Date("2000/1/1");

  const formatDate = (dateIn) => {
    if (dateIn === undefined || dateIn === null) {
      return "";
    }

    const wrkDate = new Date(dateIn);
    if (wrkDate < earliest) {
      return "";
    }

    const latestDate = new Intl.DateTimeFormat("en-US", optionsDate).format(
      wrkDate
    );

    return latestDate;
  };

  const inputDate = (dateIn) => {
    if (dateIn === undefined || dateIn === null) {
      return "";
    }

    const wrkDate = new Date(dateIn);
    if (wrkDate < earliest) {
      return "";
    }

    const dateInput = wrkDate.toISOString().split("T")[0];

    return dateInput;
  };

  const maxDate = (x) => {
    const dates = [
      new Date(x?.dateAssigned),
      new Date(x?.dateFilled),
      new Date(x?.datePickedUp),
      new Date(x?.dateReturned),
      new Date(x?.dateLost),
    ];

    const wrkDate = new Date(Math.max.apply(null, dates));
    return formatDate(wrkDate);
  };

  const setQtyIssued = (x) => {
    return Math.max.apply(Math, [
      (x?.qtyPickedUp ?? 0) - (x?.qtyReturned ?? 0) - (x?.qtyLost ?? 0),
      0,
    ]); // The apply is need for the math function
  };

  const { isError } = useQuery(
    ["keycode"],
    () =>
      getRoomsAssignments(
        "keyAll",
        "0",
        keyCode,
        null,
        null,
        unitId,
        true,
        null,
        false
      ), //Get the listing of key assignments
    {
      onError: (error) => {
        console.log(isError, error);
      },
      onSuccess: (data) => {
        const dataSet1 = data?.data?.result1;
        const dataSet2 = data?.data?.result2;

        if (dataSet1) {
          setKeyDetails(dataSet1);
        }

        if (dataSet2) {
          const keyAssignment = dataSet2
            .filter((x) => {
              return !(x.isComplete ?? 0);
            })
            .map((x) => ({
              id: x?.id ?? "",
              userid: x.userId,
              description: x.description ?? "",
              unitid: x?.unitId ?? "",
              externalid: x?.externalId ?? "",
              keysissued: setQtyIssued(x) ?? 0,
              latest: maxDate(x) ?? "",
              qtyassigned: x?.qtyAssigned ?? 0,
              assignedby: x?.assignedBy ?? "",
              dateassigned: inputDate(x?.dateAssigned),
              qtyfilled: x?.qtyFilled ?? 0,
              datefilled: inputDate(x?.dateFilled),
              filledby: x?.filledBy ?? "",
              qtypickedup: x?.qtyPickedUp ?? 0,
              datepickedupNoWrite: inputDate(x?.datePickedUp),
              pickedupbyNoWrite: x?.pickedUpBy ?? "",
              qtyreturned: x?.qtyReturned ?? 0,
              datereturnedNoWrite: inputDate(x?.dateReturned),
              returnacceptedbyNoWrite: x?.returnAcceptedBy ?? "",
              qtylost: x?.qtyLost ?? 0,
              datelostNoWrite: inputDate(x?.dateLost),
              lossreportedbyNoWrite: x?.lossReportedBy,
              iscomplete: x?.isComplete ?? 0,
              accessrequestid: x?.AccessRequestID ?? "",
            }));

          setKeyAssignments(keyAssignment);

          const transaction = dataSet2.map((x) => ({
            id: x?.id ?? "",
            userid: x?.userId ?? "",
            keysissued: setQtyIssued(x),
            description: x?.description ?? "",
            unitid: x?.unitId ?? "",
            externalid: x?.externalId ?? "",
            qtyassigned: x?.qtyAssigned ?? 0,
            assignedby: x?.assignedBy ?? "",
            dateassigned: formatDate(x?.dateAssigned),
            qtyfilled: x?.qtyFilled ?? 0,
            datefilled: formatDate(x?.dateFilled),
            filledby: x?.filledBy ?? "",
            qtypickedup: x?.qtyPickedUp ?? 0,
            datepickedup: formatDate(x?.datePickedUp),
            pickedupby: x?.pickedUpBy ?? "",
            qtyreturned: x?.qtyReturned ?? 0,
            datereturned: formatDate(x?.dateReturned),
            returnacceptedby: x?.returnAcceptedBy ?? "",
            qtylost: x?.qtyLost ?? 0,
            datelost: formatDate(x?.dateLost),
            lossreportedby: x?.lossReportedBy,
            iscomplete: (x?.isComplete ?? 0) == 1 ? "Yes" : "No",
            accessrequestid: x?.AccessRequestID ?? "",
          }));

          setTransactions(transaction);
        }
      },

      fetchPolicy: "cache",
    }
  );

  //----------writing to DB-----------------------------------------
  const updateKey = useMutation({
    mutationFn: (keysWrite) => {
      return execKeys(keysWrite.current);
    },
    onSuccess: (data) => {
      const result = data?.data?.results;

      if (result?.length ?? 0 >= 1) {
        const msg = result[0]?.message ?? "Save processed";

        alert(msg);

        if (msg != "SUCCESS: 0 Record(s) updated") {
          queryClient.invalidateQueries("fetchLocal");
          setRoomCacheData([]);
        }
      }

      setAction(null);
    },
    onError: (error) => {
      console.log("error (updateKey)", error);
    },
  });

  const updateKeyAssignment = useMutation({
    mutationFn: (keyAssignmentWrite) => {
      return execKeysAssignments(keyAssignmentWrite.current);
    },
    onSuccess: (data) => {
      const result = data?.data?.results;

      if (result?.length ?? 0 >= 1) {
        const msg = result[0]?.message ?? "Save processed";

        alert(result[0]?.message ?? "Save processed");

        if (msg != "SUCCESS: 0 Record(s) updated") {
          queryClient.invalidateQueries("fetchLocal");
          queryClient.invalidateQueries("keycode");
          setRoomCacheData([]);
        }
      }
    },
    onError: (error) => {
      console.log("error (updateKeyAssignment)", error);
    },
  });

  const handleSave = (datas, act, n) => {
    // actions delete, edit, new
    const action =
      act == "new" ? "INSERT" : act == "delete" ? "DELETE" : "UPDATE";

    // get the keyId mapped
    datas.keycode = keyCode;

    keyAssignmentWrite.current = {
      action: action,
      unitId: unitId,
      datas,
    };

    updateKeyAssignment.mutate(keyAssignmentWrite);
  };

  const writeKeysDetail = () => {
    //const { instance } = props.msalContext;
    keysWrite.current = {
      action: "UPDATE",
      unitId: unitId,
      datas: {
        keycode: keyCode,
        bldg: bldg,
        unitId: unitId,
        label: changeDetail.current?.label ?? localDetail?.label ?? "",
        requestable:
          changeDetail.current?.requestable ?? localDetail?.requestable ?? "1",
        storagelocation:
          changeDetail.current?.storageLocation ??
          localDetail?.storageLocation ??
          "",
        storagehook:
          changeDetail.current?.storageHook ?? localDetail?.storageHook ?? "",
        inventory: Number(
          changeDetail.current?.inventory ?? localDetail?.inventory ?? 0
        ),
      },
    };

    updateKey.mutate(keysWrite);
  };

  const handleUpdateLocals = () => {
    setAction("edit");
  };

  const handleConfirmLocalUpdate = () => {
    // save to DB ****************************************************
    writeKeysDetail();
  };

  const handleClear = () => {
    handleExternalReset();
  };

  const handlePanelClick = () => {
    setShowModal(true);
  };

  const trimKey = (keyId) => {
    let keyTrimmed = keyId.trim();

    const illegalChars = [" ", "-", "."];

    if (keyTrimmed.length > 5 && illegalChars.includes(keyTrimmed.charAt(5))) {
      //then an illegal character exists at character 5
      return keyTrimmed.substring(5);
    } else {
      return keyTrimmed;
    }
  };

  let cntr = 0;

  return (
    <div>
      <div className="container">
        <CenterStyle>
          {/* Future wishlist, put order & Status in for users to be able to easily order and check status of order*/}
          <Panel
            title={"Key ID: " + trimKey(keyCode)}
            label="Keys in Inventory"
            data={inventory == "" ? 0 : inventory}
            headers=""
            onClick={handlePanelClick}
            button={setShowModal}
          />
        </CenterStyle>

        {showModal && (
          <Modal
            setShowModal={setShowModal}
            content={
              <Table
                title="Keys by Unit"
                headers={[
                  {
                    field: "unitId",
                    label: "Unit",
                  },
                  {
                    field: "issued",
                    label: "Issued",
                  },
                  {
                    field: "inventory",
                    label: "Inventory",
                  },
                  {
                    field: "available",
                    label: "Available",
                  },
                ]}
                data={keyDetails}
              />
            }
          />
        )}

        <HalfWidth>
          <h3>Counts</h3>
          <Input
            width="100%"
            label="Total Keys Out"
            data={localDetail?.issued ?? 0}
            readonly={true}
          />
          <Input
            width="100%"
            label="Available"
            data={inventory - issued}
            readonly={true}
          />
          {isLoadingLocal || isFetchingLocal ? (
            <p>Loading data...</p>
          ) : (
            //<></>
            localKeyData.slice(0, 1).map((item) => (
              <LocalDetail
                key={cntr}
                value={localDetail?.[item.item] ?? 0}
                label={item.label}
                //label={cntr + ":" + item.item + ":" + localDetail?.[item.item]}
                item={item.item}
                type={item?.type ?? "text"}
                maxLength={item?.maxLength ?? 0}
                onChange={onLocalChange}
                ref={inputRef[cntr++]}
              />
            ))
          )}
        </HalfWidth>
        <HalfWidth>
          <h3>Details</h3>
          <Input
            width="100%"
            label="Building Code (Engraved on Key)"
            data={
              localDetail?.keyCode?.substring(
                0,
                localDetail?.keyCode?.indexOf("-") ?? 0
              ) ?? ""
            }
            readonly={true}
          />
          <Input
            width="100%"
            label="Key Type"
            data={localDetail?.key_type ?? ""}
            readonly={true}
          />
          <Input
            width="100%"
            label="Key Class"
            data={
              (localDetail?.key_class ?? "") +
              " - " +
              (localDetail?.class_desc ?? "")
            }
            readonly={true}
          />
        </HalfWidth>

        {isLoadingLocal || isFetchingLocal ? (
          <p>Loading data...</p>
        ) : (
          localKeyData
            .slice(1)
            .map((item) => (
              <LocalDetail
                key={cntr}
                value={localDetail[item.item] ?? ""}
                label={item.label}
                item={item.item}
                type={item?.type ?? "text"}
                maxLength={item?.maxLength ?? 0}
                onChange={onLocalChange}
                ref={inputRef[cntr++]}
              />
            ))
        )}

        {action === "edit" ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              onClick={() => handleConfirmLocalUpdate()}
              label="Confirm Changes"
            >
              Confirm Changes
            </Button>
            <Button onClick={() => setAction(null)} label="Cancel">
              Cancel
            </Button>
            <Button onClick={() => handleClear()} label="Clear Changes">
              Clear Changes
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              label="Save Changes"
              onClick={() => handleUpdateLocals()}
            ></Button>
            <Button onClick={() => handleClear()} label="Clear Changes">
              Clear Changes
            </Button>
          </div>
        )}
      </div>
      <div>
        <Table
          title="Rooms Opened by Key"
          headers={[
            {
              field: "building",
              label: "Building",
            },
            {
              field: "fl_id",
              label: "Floor Number",
            },
            {
              field: "rm_id",
              label: "Room Number",
            },
          ]}
          data={rooms}
        />

        <Table
          title="Personnel with Key"
          headers={[
            {
              field: "id",
              label: "transaction",
              include: ["table", "edit"],
            },
            {
              field: "unitid",
              label: "unit",
              readonly: true,
              maxlength: 6,
              type: "text",
              include: ["table", "edit"],
            },
            {
              field: "unitid",
              defaults: unitId,
              label: "unit",
              readonly: true,
              maxlength: 6,
              type: "text",
              include: ["new"],
            },
            {
              field: "externalid",
              label: "External Id",
              include: ["edit"],
            },
            {
              field: "accessrequestid",
              label: "access Request Id",
              include: ["edit"],
            },
            {
              field: "userid",
              label: "User Id",
              required: true,
              readonly: false,
              maxlength: 9,
              type: "text",
              row: 2,
              column: 1,
            },
            {
              field: "description",
              label: "Description",
              include: ["edit", "table"],
              readonly: false,
              maxlength: 64,
              type: "text",
              row: 2,
              column: "span 2",
            },
            {
              field: "qtypickedup",
              label: "Qty Picked Up",
              include: ["new"],
              required: true,
              readonly: false,
              type: "int",
              maxlength: 9,
              row: 2,
              column: 2,
            },
            {
              field: "description",
              label: "Description",
              include: ["new"],
              readonly: false,
              maxlength: 64,
              type: "text",
              row: 3,
              column: "span 2",
            },
            {
              field: "iscomplete",
              label: "Is Complete?",
              include: ["edit"],
              readonly: false,
              type: "checkbox",
              row: 2,
              column: 4,
            },
            {
              field: "keysissued",
              label: "Keys Issued",
              include: ["table"],
            },
            {
              field: "latest",
              label: "Latest Transaction",
              include: ["table"],
            },
            {
              field: "qtypickedup",
              label: "Qty Picked Up",
              include: ["edit"],
              readonly: false,
              required: true,
              type: "int",
              maxlength: 9,
              row: 5,
              column: 2,
            },
            {
              field: "datepickedupNoWrite",
              label: "Date Picked Up",
              include: ["edit"],
              type: "date",
              maxlength: 10,
              row: 5,
              column: 3,
            },
            {
              field: "pickedupbyNoWrite",
              label: "Picked Up By",
              include: ["edit"],
              type: "name",
              maxlength: 64,
              row: 5,
              column: 4,
            },
            {
              field: "qtyreturned",
              label: "Returned",
              include: ["edit"],
              readonly: false,
              type: "int",
              maxlength: 9,
              row: 6,
              column: 2,
            },
            {
              field: "datereturnedNoWrite",
              label: "Date Returned",
              include: ["edit"],
              type: "date",
              maxlength: 10,
              row: 6,
              column: 3,
            },
            {
              field: "returnacceptedbyNoWrite",
              label: "Returned Up By",
              include: ["edit"],
              type: "name",
              maxlength: 64,
              row: 6,
              column: 4,
            },
            {
              field: "qtylost",
              label: "Lost",
              include: ["edit"],
              readonly: false,
              type: "int",
              maxlLength: 9,
              row: 7,
              column: 2,
            },
            {
              field: "datelostNoWrite",
              label: "Date Lost",
              include: ["edit"],
              type: "date",
              maxlength: 10,
              row: 7,
              column: 3,
            },
            {
              field: "lossreportedbyNoWrite",
              label: "Loss Reported By",
              include: ["edit"],
              type: "name",
              maxlength: 64,
              row: 7,
              column: 4,
            },
          ]}
          actions={["new", "edit", "delete"]}
          handleSave={handleSave}
          data={keyAssignments}
        />

        <span style={{ width: "100%", height: "1.5em" }}></span>
        <FullWidth>
          <Notes keyId={keyCode} />
        </FullWidth>
        <Table
          title="Transaction"
          headers={[
            {
              field: "id",
              label: "transaction",
            },
            {
              field: "userid",
              label: "user Id",
            },
            {
              field: "keysissued",
              label: "Keys Issued",
              include: ["table"],
            },
            {
              field: "description",
              label: "Description",
              include: ["view"],
              row: 2,
              column: "span 2",
            },
            {
              field: "unitid",
              label: "unit",
              include: ["view"],
              row: 2,
            },
            {
              field: "externalid",
              label: "External Id",
              row: 1,
              column: 4,
              include: ["view"],
            },
            {
              field: "qtyassigned",
              label: "Assigned",
              row: 3,
              column: 2,
            },
            {
              field: "dateassigned",
              label: "Date Assigned",
              row: 3,
              column: 3,
            },
            {
              field: "assignedby",
              label: "Assigned By",
              row: 3,
              column: 4,
              include: ["view"],
            },
            {
              field: "qtyfilled",
              label: "Filled",
              row: 4,
              column: 2,
            },
            {
              field: "datefilled",
              label: "Date Filled",
              row: 4,
              column: 3,
            },
            {
              field: "filledby",
              label: "Filled By",
              row: 4,
              column: 4,
              include: ["view"],
            },
            {
              field: "qtypickedup",
              label: "Picked Up",
              row: 5,
              column: 2,
            },
            {
              field: "datepickedup",
              label: "Date Picked Up",
              row: 5,
              column: 3,
            },
            {
              field: "pickedupby",
              label: "Picked Up By",
              row: 5,
              column: 4,
              include: ["view"],
            },
            {
              field: "qtyreturned",
              label: "Returned",
              row: 6,
              column: 2,
            },
            {
              field: "datereturned",
              label: "Date Returned",
              row: 6,
              column: 3,
            },
            {
              field: "returnacceptedby",
              label: "Returned Up By",
              row: 6,
              column: 4,
              include: ["view"],
            },
            {
              field: "qtylost",
              label: "Lost",
              row: 7,
              column: 2,
            },
            {
              field: "datelost",
              label: "Date Lost",
              row: 7,
              column: 3,
            },
            {
              field: "lossreportedby",
              label: "Loss Reported By",
              row: 7,
              column: 4,
              include: ["view"],
            },
            {
              field: "iscomplete",
              label: "Cmplt?",
              row: 2,
            },
            {
              field: "accessrequestid",
              label: "access Request Id",
              row: 1,
              column: 3,
            },
          ]}
          actions={["view"]}
          handleSave={handleSave}
          data={transactions}
        />
      </div>
    </div>
  );
};
export default KeyDetails;
