// git command to add a local branch
// git switch -c add-templating remotes/origin/add-templating

import { ExcelUpload } from "components/Uploads/ExcelUpload/ExcelUpload";

const Uploads = () => {
  return (
    <div>
      <div style={{ display: "flex" }}></div>
      <b>Keys Data Uploads</b>
      <ExcelUpload
        title="KeysMaster Table Upload"
        backEndCall="/api/requests/execKeysMasterMassUpload"
        defaultBatchSize={2000}
      />
      <ExcelUpload
        title="KeysRooms Table Upload"
        backEndCall="/api/requests/execKeysRoomsMassUpload"
        defaultBatchSize={2000}
      />
      <ExcelUpload
        title="KeysAssignments Table Upload"
        backEndCall="/api/requests/execKeysAssignmentsMassUpload"
        defaultBatchSize={2000}
      />
      <ExcelUpload
        title="Keys Table Upload (deperecated)"
        backEndCall="/api/requests/execKeysMassUpload"
      />
      <b>Rooms Data Uploads</b>
      <ExcelUpload
        title="Percentage Table Upload"
        backEndCall="/api/requests/execPercentageMassUpload"
        defaultBatchSize={500}
      />
      <b>Authorization Data Uploads</b>
      <ExcelUpload
        title="AuthMap Table Upload"
        backEndCall="/api/requests/execAuthMapMassUpload"
      />
      <b>Email Owner Uploads</b>
      <ExcelUpload
        title="emOccupancyEmail Table Upload"
        backEndCall="/api/requests/execEmMassUpload"
        defaultBatchSize={5000}
      />
      <b>Email List Upload From ICard</b>
      <ExcelUpload
        title="iCardDaily Table Upload"
        backEndCall="/api/requests/execICardDailyMassUpload"
        defaultBatchSize={5000}
      />
      <b>Authorization List Upload for Authority</b>
      <ExcelUpload
        title="authz Table Upload"
        backEndCall="/api/requests/execAuthzMassUpload"
        defaultBatchSize={5000}
      />
    </div>
  );
};
export default Uploads;
