import Button from "components/Common/Button/Button";
import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { BigButton } from "components/Common/BigButton/BigButton";
import axiosInstance from "hooks/Common/axiosClient";
import { SendToken } from "hooks/ValidateToken";

export const Login = () => {
  const isAuthenticated = useIsAuthenticated();

  const handleSignIn = async () => {
    axiosInstance.login();
  };

  if (axiosInstance.MsalInstance) {
    SendToken(axiosInstance.MsalInstance); //function forces auth, so login button is rendered a bit moot
  }

  return (
    <div>
      {!isAuthenticated ? (
        <Button label="Log In" onClick={handleSignIn} />
      ) : (
        <div>
          <p>Login Success</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              paddingTop: "2rem",
              paddingBottom: "2rem",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <BigButton path="/requestkeys" label="Request Keys" />
              <BigButton path="/myrequests" label="My Requests" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
