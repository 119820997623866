import axiosInstance from "./Common/axiosClient";

const baseURL = "https://aimintegrationfunction.azurewebsites.net/api/";
const db = "keys/";

export const getDepartmentRequests = async (department) => {
  const response = await axiosInstance.get(
    "/api/requests/department/" + department
  );
  return response;
};

export const getUserRequests = async (user) => {
  const response = await axiosInstance.get("/api/requests/user/" + user);
  return response;
};

export const createRequest = async (jsonHeader, jsonBody) => {
  const response = await axiosInstance.post("/api/keys/execKeysRequests", {
    action: "insert",
    Headers: jsonHeader,
    Body: jsonBody,
  });

  return response;
};

export const createRequestEntries = async (json) => {
  let options = "execrequestentries?action=insert";
  const response = await axiosInstance.post(`${baseURL}${db}${options}`, {
    json,
  });
  return response;
};

export const getKeyAssignments = async (id) => {
  const response = await axiosInstance.get(
    `/api/requests/assignments/request/${id}`
  );

  return response;
};

export const addKeyAssignments = async (id, data) => {
  let options = "execkeyassignments?action=insert&id=";
  const response = await axiosInstance.post(`${baseURL}${db}${options}${id}`, {
    data,
  });
  return response;
};

export const updateKeyAssignments = async (id, data) => {
  let options = "execkeyassignments?action=update&id=";
  const response = await axiosInstance.post(`${baseURL}${db}${options}${id}`, {
    data,
  });
  return response;
};
