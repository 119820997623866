import React from "react";
import { useEffect, useState } from "react";
import {
  getRemovableKeyholderHeader,
  getRemoveableKeyholderRows,
} from "hooks/Keyholders/keyholdersTableFunctions";
import { StyledTable } from "components/Common/Styles/TableStyles";

const KeyholdersSelected = ({ keyholder, setKeyholder }) => {
  const [rows, setRows] = useState(null);
  const [header, setHeader] = useState(null);

  useEffect(() => {
    setRows(getRemoveableKeyholderRows(keyholder, setKeyholder));
    setHeader(getRemovableKeyholderHeader());
  }, [keyholder]);

  return (
    <>
      {keyholder.users?.length === 0 ? (
        <>
          <p
            style={{
              textAlign: "center",
              margin: "0 auto",
              marginBottom: "1rem",
              color: "var(--illiniDarkBlue)",
            }}
          >
            No keyholders selected
          </p>
        </>
      ) : (
        <>
          <StyledTable>
            {header}
            {rows}
          </StyledTable>
        </>
      )}
    </>
  );
};

export default KeyholdersSelected;
