import styled from "styled-components";

export const StyledBigButton = styled.div`
  border: 6px dashed var(--illiniDarkBlue);
  border-radius: 10px;
  width: 300px;
  height: 300px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 2rem;

  &:hover {
    border: 6px solid var(--illiniOrange);
    background: rgba(255, 95, 5, 0.25);
  }
`;
