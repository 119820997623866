import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";

export function GetUser() {
  const { userName, setUserName } = useGlobalContext();

  const { instance } = useMsal();

  useEffect(() => {
    if (userName === undefined || userName === null || userName === "") {
      let name = instance.getActiveAccount();

      setUserName({
        uin: name?.idTokenClaims?.uin,
        fullName: name?.name,
        firstName: name?.idTokenClaims?.given_name,
        lastName: name?.idTokenClaims?.family_name,
      });
    }

    // eslint-disable-next-line
  }, [instance]);
}
