import {
  TableRow,
  TableHead,
  RoundLeft,
  RoundRight,
} from "../../components/Common/Styles/TableStyles";

import Button from "components/Common/Button/Button";
import Image from "components/Common/UserImage/UserImage";

export function getSelectableKeyholderHeader() {
  return (
    <TableHead>
      <tr>
        <RoundLeft scope="col" width="2%">
          n
        </RoundLeft>
        <th scope="col">Image</th>
        <th scope="col">First Name</th>
        <th scope="col">Last Name</th>
        <RoundRight scope="col">Add</RoundRight>
      </tr>
    </TableHead>
  );
}

export function getSelectableKeyholderRows({
  keyholder, // Add keyholdersSelected as a parameter
  setKeyholder, // Add setKeyholdersSelected as a parameter
  users,
}) {
  users = users.filter((user) => {
    return !keyholder.users.some((keyholder) => keyholder.userId === user.uin);
  });

  const onChange = (e, user) => {
    //check if user is already in list
    if (keyholder.users.some((keyholder) => keyholder.userId === user.uin)) {
      return;
    }

    setKeyholder({
      type: "another",
      users: [
        ...keyholder.users,
        {
          userId: user.uin,
          firstName: user.name.firstName,
          lastName: user.name.lastName,
        },
      ],
    });
  };

  const rows = users.map((user, index) => (
    <TableRow key={user}>
      <td>{index + 1}</td>
      <td>
        <Image user={user?.uin}></Image>
      </td>
      <td>{user?.name.firstName}</td>
      <td>{user?.name.lastName}</td>
      <td>
        <Button onClick={(e) => onChange(e, user)} label="Add" />
      </td>
    </TableRow>
  ));

  return rows;
}

export function getRemovableKeyholderHeader() {
  return (
    <TableHead>
      <tr>
        <RoundLeft scope="col" width="2%">
          n
        </RoundLeft>
        <th scope="col">Image</th>
        <th scope="col">First Name</th>
        <th scope="col">Last Name</th>
        <RoundRight scope="col">Remove</RoundRight>
      </tr>
    </TableHead>
  );
}

export function getRemoveableKeyholderRows(
  keyholder, // Add keyholdersSelected as a parameter
  setKeyholder // Add setKeyholdersSelected as a parameter
) {
  const keyholders = keyholder.users;
  let disabled = false;

  if (keyholder.type === "myself") {
    disabled = true;
  }

  const onChange = (user) => {
    // //remove row from keyholders selected

    const newUsers = keyholders.filter((keyholder1) => {
      return keyholder1 !== user.user;
    });

    console.log(newUsers);
    setKeyholder({
      type: "another",
      users: newUsers,
    });

    console.log("Keyholder removed: ", keyholder);
  };

  const rows = keyholders.map((keyholder, index) => (
    <TableRow key={keyholder}>
      <td>{index + 1}</td>
      <td>
        <Image user={keyholder.userId}></Image>
      </td>
      <td>{keyholder.firstName}</td>
      <td>{keyholder.lastName}</td>
      <td>
        <Button
          disabled={disabled}
          onClick={() => onChange({ user: keyholder })}
          label="Remove"
        />
      </td>
    </TableRow>
  ));

  return rows;
}
