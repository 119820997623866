import styled from "styled-components";

export const KeysNav = styled.div`
  width: 1180px;
  justify-content: flex-start;
  position: relative;

  margin: 0 auto;

  @media (max-width: 1180px) {
    width: 100%;
  }
`;
export const InnerNav = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  padding: 1rem 0 1rem 0;
`;
