import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Layout from "components/Common/Layout/Layout";

import { Login } from "components/Login/Login";

import Keys from "../components/Keys/Keys/Keys";
import KeyDetail from "../components/Keys/KeyDetails/KeyDetail";
import Approvals from "../components/Approvals/Approvals/Approvals";

import RequestKeys from "components/RequestAKey/KeyRequests/RequestKeys/RequestKeys";
import MyRequests from "components/MyRequests/MyRequests/MyRequests";
// import UserRequests from "../components/RequestAKey/Requests/Requests";

import Rooms from "components/Rooms/Rooms/Rooms";
import GetRoomDetail from "components/Rooms/getRoomDetail/getRoomDetail";

import Uploads from "../components/Uploads/Uploads/Uploads";
import { useIsAuthenticated } from "@azure/msal-react";

const Page404 = () => <h1>Four:oh:four</h1>;

function AppRoutes() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Router>
      <Routes>
        <Route element={<Layout title="Key Request Management System" />}>
          <Route path="/" element={<Login />} />
        </Route>

        {isAuthenticated ? (
          <>
            <Route element={<Layout title="Keys" />}>
              <Route path="keys" element={<Keys />} />
              <Route path="keys/:id" element={<KeyDetail />} />
            </Route>

            <Route element={<Layout title="Key Request Approvals" />}>
              <Route path="approvals" element={<Approvals />} />
            </Route>

            {/* <Route element={<Layout title="Request a Key" />}>
              <Route path="/requests" element={<UserRequests />} />
            </Route> */}

            <Route element={<Layout title="Uploads" />}>
              <Route exact path="uploads" element={<Uploads />} />
            </Route>

            <Route element={<Layout title="Request Keys" />}>
              <Route path="/requestkeys" element={<RequestKeys />} />
            </Route>

            <Route element={<Layout title="My Requests" />}>
              <Route path="/myrequests" element={<MyRequests />} />
            </Route>

            <Route element={<Layout title="Rooms" />}>
              <Route path="rooms" element={<Rooms />} />
              <Route path="rooms/:id" element={<GetRoomDetail />} />
            </Route>
          </>
        ) : (
          <Route path="*" element={<Login />} />
        )}

        <Route element={<Layout title="Error" />}>
          <Route element={Page404} />
        </Route>
      </Routes>
    </Router>
  );
}

export default AppRoutes;
