import {
  TableRow,
  TableHead,
  RoundLeft,
  RoundRight,
  TableLink,
} from "../../components/Common/Styles/TableStyles";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import Button from "components/Common/Button/Button";

import { SVGRoomList } from "components/Context/getRoomsSVG/getRoomsSVG";

export function getKeyRows(
  rows,
  isLoading,
  isError,
  error,
  results,
  saveResults,
  saveBuilding,
  building,
  saveSearch,
  search,
  saveSrch,
  offset = null
) {
  //const breakline = "\u000A";
  let srch = 0;

  if (isLoading) {
    rows.current = (
      <TableRow key={1}>
        <td style={{ textAlign: "center" }} colSpan="6">
          Loading...
          <br />
          <ReactLoading type="bubbles" color="#000000" height="300" />
        </td>
      </TableRow>
    );
  } else if (isError) {
    rows.current = (
      <TableRow key={1}>
        <td>{error.message}</td>
      </TableRow>
    );
  } else {
    if (
      saveResults.current === results &&
      saveBuilding.current === building &&
      saveSearch.current === search
    ) {
      srch = -1;
    } else {
      saveResults.current = results;
      saveBuilding.current = building;
      saveSearch.current = search;

      let filter = building?.toString() ?? "";
      const saveArray = [];

      offset.current = 0;

      rows.current = results
        ?.filter((n) => {
          const wrkKeyCode = n?.keyCode;
          const wrkUnitId = n?.unitId;

          if (
            saveArray?.keyCode !== wrkKeyCode ||
            saveArray?.unitId !== wrkUnitId
          ) {
            saveArray["keyCode"] = wrkKeyCode;
            saveArray["unitId"] = wrkUnitId;
          } else {
            if (offset) offset.current++;
            if (filter === "") return false;
          }

          if (filter !== "") if (n.bldg !== filter) return false;

          if (search !== "" || filter !== "") {
            srch = srch ?? 0;

            if (n.keyCode?.toLowerCase().includes(search?.toLowerCase()))
              srch++;
            else return false;
          } else srch = results?.length;

          return n;
        })
        .map((key, index) => (
          <TableRow key={key.id}>
            <td>{index + 1}</td>
            <td>
              {key?.keyCode.substring(key?.keyCode.indexOf("-") + 1) ?? ""} (
              {key?.keyCode.substring(0, key?.keyCode.indexOf("-")) ?? ""})
            </td>
            <td>{key?.unitId}</td>
            <td>
              <SVGRoomList inputString={key?.rooms} />
            </td>
            <td>{key?.label ?? ""}</td>
            <td>{key?.key_type ?? ""}</td>
            <td>{(key?.inventory ?? 0) - (key?.issued ?? 0)}</td>
            <td>{key?.issued ?? 0}</td>
            <td>{key?.inventory ?? 0}</td>
            <td>
              <Link
                to={
                  "detail/?unit=" +
                  (key?.unitId ?? "") +
                  "&keyid=" +
                  (key?.keyCode ?? "")
                }
              >
                <TableLink>View</TableLink>
              </Link>
            </td>
          </TableRow>
        ));
    }
  }

  if (srch > -1) saveSrch.current = srch;
}

export function getKeyHeader() {
  return (
    <TableHead>
      <tr>
        <RoundLeft scope="col" width="2%">
          n
        </RoundLeft>
        <th scope="col">Key Code (Key Building Code)</th>
        <th scope="col">Unit</th>
        <th scope="col">Rooms</th>
        <th scope="col">Label</th>
        <th scope="col">Type</th>
        <th scope="col">Available</th>
        <th scope="col">Issued</th>
        <th scope="col">Total Keys</th>
        <RoundRight scope="col">Action</RoundRight>
      </tr>
    </TableHead>
  );
}

export function getSelectableKeyHeader() {
  return (
    <TableHead>
      <tr>
        <RoundLeft scope="col" width="2%">
          n
        </RoundLeft>
        <th scope="col">Building Name</th>
        <th scope="col">Label</th>
        <RoundRight scope="col">Add</RoundRight>
      </tr>
    </TableHead>
  );
}

export function getSelectableKeyRows(
  rows,
  isLoading,
  isError,
  error,
  results,
  saveResults,
  saveBuilding,
  building,
  saveSearch,
  search,
  saveSrch,
  roomsSelected,
  setRoomsSelected,
  buildingsSelected,
  setBuildingsSelected,
  keysSelected,
  setKeysSelected,
  offset
) {
  //const breakline = "\u000A";
  let srch = 0;

  const onChange = async (e, keySelected, index) => {
    //check if key is already in list
    if (keysSelected.some((key1) => key1 === keySelected)) {
      return;
    }
    setKeysSelected((keysSelected) => [...keysSelected, keySelected]);

    e.target.disabled = true;
    e.target.innerHTML = "Added";

    //filter it out of rows.current
    rows.current = rows.current.filter((key) => {
      return key.bl_id !== keySelected.bl_id;
    });

    keySelected.index = index;
  };

  if (isLoading) {
    rows.current = (
      <TableRow key={1}>
        <td style={{ textAlign: "center" }} colSpan="6">
          Loading...
          <br />
          <ReactLoading type="bubbles" color="#000000" height="300" />
        </td>
      </TableRow>
    );
  } else if (isError) {
    rows.current = (
      <TableRow key={1}>
        <td>{error.message}</td>
      </TableRow>
    );
  } else {
    saveResults.current = results;
    saveBuilding.current = building;
    saveSearch.current = search;

    if (results && results.length > 0) {
      let filter = building?.toString() ?? "";
      const saveArray = [];

      offset.current = 0;

      //filter out any key that doesn't have a label length > 0 and filter out by search string
      results = results?.filter(
        (key) =>
          key?.label?.length > 0 &&
          key?.label?.toLowerCase().includes(search?.toLowerCase())
      );

      // MAKE SURE TO PASS BUILDING ID WITH KEY TO BACKEND

      rows.current = results
        ?.filter((n) => {
          const wrkKeyCode = n?.keyCode;
          const wrkUnitId = n?.unitId;

          if (
            saveArray?.keyCode !== wrkKeyCode ||
            saveArray?.unitId !== wrkUnitId
          ) {
            saveArray["keyCode"] = wrkKeyCode;
            saveArray["unitId"] = wrkUnitId;
          } else {
            if (offset) offset.current++;
            if (filter === "") return false;
          }

          if (filter !== "") if (n.bldg !== filter) return false;

          if (search !== "" || filter !== "") {
            srch = srch ?? 0;

            if (n.label?.toLowerCase().includes(search?.toLowerCase())) srch++;
            else return false;
          } else srch = results?.length;

          return n;
        })
        .map((key, index) => (
          <TableRow key={key.id}>
            <td>{index + 1}</td>
            <td>{key?.bldgName}</td>
            <td>{key?.label ?? ""}</td>
            <td>
              <Button
                onClick={(e) => onChange(e, key, index)}
                id={key.id}
                label="Add"
              />
            </td>
          </TableRow>
        ));
    }
  }
  if (srch > -1) saveSrch.current = srch;
}

export function getRemovableKeyHeader() {
  return (
    <TableHead>
      <tr>
        <RoundLeft scope="col" width="2%">
          n
        </RoundLeft>
        <th scope="col">Building</th>
        <th scope="col">Label</th>
        <RoundRight scope="col">Remove</RoundRight>
      </tr>
    </TableHead>
  );
}

export function getRemoveableKeyRows(
  keysSelected, // Add keysSelectedsSelected as a parameter
  setKeysSelected // Add setKeysSelectedsSelected as a parameter
) {
  const onChange = (keySelected) => {
    const newKeys = keysSelected.filter((key1) => {
      return key1 !== keySelected;
    });

    setKeysSelected(newKeys);

    //enable button
    const button = document.getElementById(keySelected.id);
    if (button) {
      button.disabled = false;
      button.innerHTML = "Add";
    }
  };

  const rows = keysSelected.map((keySelected, index) => (
    <TableRow key={keySelected}>
      <td>{index + 1}</td>
      <td>{keySelected?.bldgName}</td>
      <td>{keySelected?.label}</td>
      <td>
        <Button onClick={() => onChange(keySelected)} label="Remove" />
      </td>
    </TableRow>
  ));

  return rows;
}
