import styled from "styled-components";

export const MenuDiv = styled.div`
  background: var(--darkGrey);
  display: flex;
  justify-content: space-around;
`;

export const Container = styled.nav`
  font-family: 'Source Sans Pro';
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: flex-start;


  .mainLink{
    color: var(--illiniDarkBlue);
    width: 100%;
    height: 50px;
    padding: 0;
    margin-bottom: 0;
    padding: 0.5rem 0.5rem 0px 0.5rem;
  }
  .secondaryLink{
    color:white;

  }
  .nav-active{
  color:white!important;}
  .secondaryLink:hover{
    text-decoration:underline;
  }

  .secondaryLink:focus{
    text-decoration:underline;
  }

  .mainLink:hover{
    text-decoration:underline;
    border:1px solid (var(--illiniOrange);
    background: white;

  }

  .mainLink:focus{
        text-decoration:underline;
    font-style:italics:
  }

  .img{
    width:12px;
  }

  ul{
    margin-left:0px;
    margin-bottom:0;

  }
  @media (min-width: 992px) {
    font-size: 0.85rem;
    display: flex;
  }
  
  @media(max-width:991px){
    display:none;
  }

  @media (min-width: 1200px) {
    font-size: 1rem;
  

  ul li {
    display: flex;
    justify-content:space-between;
    margin: 0 10px 0 0;
    float: left;
    height: 50px;
    position: relative;
    padding-inline: 10px;

    
}

button{
      border: none;
    height: 50px;
    width: 100%;
    padding-top: 10px;
    display: flex;
    appearance: none;
}
    a {
      display: flex;
    padding: .5rem .5rem 0px .5rem;
          transition: all 0.2s ease;

    }


  ul li:last-child {
    margin: 0;
  }

  ul li > ul {
    display: none;
  }

  .nav-active{
      background-color: var(--illiniDarkBlue);
    color: white;
    border-radius: .25rem;
    text-decoraction:underline;
    
  }




    .activeUL{
    display:block;
    width: fit-content;
  }
  .active{
  background-color: var(--illiniDarkBlue);
    color: white;
    border-radius: .25rem;
        display:flex;
    width: fit-content;
    text-decoration:underline;

  }

  

  ul ul li a:hover{
    text-decoraction:underline!important;
  }

  .nav{
    cursor:pointer;
  }

  ul li div img{
   margin-left:8px;
   margin-bottom: 2px;
 }


  ul ul {
    animation: fadeInMenu 0.3s both ease-in;
    display: none;
    position: absolute;
    left: 0;
    margin: 0;
    top: 50px;
    text-transform: none;
    background: var(--illiniDarkBlue);
    color:white;
    padding: 15px 10px 10px 10px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    min-width:200px;
    max-width:400px;    
    margin-left: .5rem;
    z-index:100;
    
  }

  ul ul li {
    width: auto;
    min-width: 170px;
  }

  ul ul li a {
    padding: 5px 10px;
    white-space: nowrap;
  }
  

  @keyframes fadeInMenu {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
`;
