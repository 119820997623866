import axiosInstance from "../Common/axiosClient";

export const getEAC = async () => {
  const response = await axiosInstance.get(
    "/api/lists/getelectronicaccesscontrol"
  );

  return response;
};

export const getNST = async () => {
  const response = await axiosInstance.get("/api/lists/getnotesharetype");

  return response;
};

export const getKeyRooms = async (roomId) => {
  const response = roomId
    ? await axiosInstance.get("/api/keys/getKeyRooms/" + roomId)
    : await axiosInstance.get("/api/keys/getKeyRooms");

  return response;
};

export const execRooms = async (json) => {
  const response = await axiosInstance.post("/api/keys/execRooms", {
    json,
  });

  return response;
};

export const execKeysRequests = async (json) => {
  const response = await axiosInstance.post("/api/keys/execKeysRequests", {
    json,
  });

  return response;
};

export const execKeysAssignments = async (json) => {
  const response = await axiosInstance.post("/api/keys/execKeysAssignments", {
    json,
  });

  return response;
};

export const execKeys = async (json) => {
  const response = await axiosInstance.post("/api/keys/execKeys", {
    json,
  });

  return response;
};

export const execNote = async (json) => {
  const response = await axiosInstance.post("/api/keys/ExecNote", { json });
  return response;
};

//APIs below this are Pending review

export const getKeys = (department, building) => {
  const response = department
    ? axiosInstance.get("/api/keys/getkeys/" + department + "/" + building)
    : axiosInstance.get("/api/keys/getBkeys/" + building);

  return response;
};

export const getBldgKeys = async (building) => {
  const response = await axiosInstance.get("/api/keys/getBkeys/" + building);

  return response;
};

export const getKeyDetails = async (userOrg, keyCode) => {
  const response = await axiosInstance.get(
    "/api/keys/getKeyDetails/" + userOrg + "/" + keyCode
  );

  return response;
};

export const updateKeyDetails = async (json, instance) => {
  const response = await axiosInstance.post(
    "/api/keys/updateKeyDetails/",
    json
  );

  return response;
};

export const createKeyAssignment = async (json) => {
  const response = await axiosInstance.post("/api/keys/assignments/create", {
    json,
  });

  return response;
};

export const updateKeyAssignments = async (json) => {
  // note: was a put call
  const response = await axiosInstance.post("/api/keys/assignments/update", {
    json,
  });

  return response;
};

// export const getKeyNotes = async (userOrg, keyCode) => {
//   const response = await axiosInstance.get(
//     "/api/keys/getKeyNotes/" + userOrg + "/" + keyCode
//   );
//   console.log("/api/keys/getKeyNotes/" + userOrg + "/" + keyCode);

//   return response;
// };

// export const updateKeyNotes = async (json) => {
//   const response = await axiosInstance.post("/api/keys/updateKeyNotes", {
//     json,
//   });

//   return response;
// };
