import axiosInstance from "hooks/Common/axiosClient";

const parseParm = (item, ret = "$ALL") => {
  const result =
    item !== undefined && item !== null && item !== false && item.trim() !== ""
      ? item
      : ret;

  return "/" + result;
};

const parseBool = (item) => {
  const result = item !== undefined && item !== null && item ? "1" : "null";

  return "/" + result;
};

const getRoomsAssignments = async (
  detail, // (keyList), (Keydetail or all), (requests or all), (requestEntries or all)
  cache, // Define the cache for the call
  keyCode, // This will filter on a specific key code. This is only valid when a keyList or keyDetail values being returned
  userId, // This will interrogate the user (keysAssignments.userId / accessRequests.accessHolder)
  accessRequestId, // Access Identifier table:accessrequests
  dp_id_and, // Department filter
  isActive = null, // Default to ignore active status
  isComplete = null, // Default to ignore completed status
  isArchived = null // Default to ignore archived transaction
) => {
  const response = await axiosInstance.get(
    "/api/lists/getRoomsAssignments" +
      parseParm(detail, "keyList") +
      parseParm(keyCode) +
      parseParm(userId) +
      parseParm(accessRequestId) +
      parseParm(dp_id_and) +
      parseBool(isActive) +
      parseBool(isComplete) +
      parseBool(isArchived) +
      parseParm(cache, "0")
  );

  return response;
};

const getRoomGeneral = async (
  detail, // (full,all) [this is room data], (bl, building), (dp,department), (dv,division), (bu,college), (ch,chart), (key), (keyList)
  cache, // Define the cache for the call
  division, // if passed will set the dafault division (department) (sometimes college but this in incorrect) filter
  department, // if passed will set default department (unit) filter
  building,
  floor,
  room,
  keysFilter = false, // Defaults to false if true then a value will only be returned if a key is found
  keysDatabase = false, // Force the query to interrogate the keys database
  keyDetailExist = false, // Force the query to require the key and room to exist
  keyCode = "", // Keycode search option partial search 3 character minimum for detail: keyList
  // Note if this is set it will force the interrogation of the keys database
  // because AIM data is not the best
  labels = "", // Keycode search option partial search 3 character minimum
  security = true //Should security be interrogated for the call
) => {
  const response = await axiosInstance.get(
    "/api/lists/getRoomGeneral" +
      parseParm(detail, "all") +
      parseParm(division) +
      parseParm(department) +
      parseParm(building) +
      parseParm(floor) +
      parseParm(room) +
      parseParm(keyCode) +
      parseParm(labels) +
      parseBool(security) +
      parseBool(keysFilter) +
      parseBool(keysDatabase) +
      parseBool(keyDetailExist) +
      parseParm(cache, "0")
  );

  return response;
};

const getRoomSVG = async (building, floor, room) => {
  const response = await axiosInstance.get(
    `/api/lists/getRoomMap/${building}/${floor}/${room}`
  );

  return response;
};

//APIs below this are Pending review
export { getRoomGeneral, getRoomSVG, getRoomsAssignments };
