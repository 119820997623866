import { Fragment, useEffect } from "react";
import ButtonLarge from "components/Common/ButtonLarge/ButtonLarge";
import UsersList from "components/RequestAKey/UserList/UsersList";
import { StyledContainer, StyledContainer2 } from "./KeyholderStep.style";
import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";

import KeyholdersSelected from "../KeyholdersSelected/KeyholdersSelected";

const KeyRequestKeyholderStep = ({ keyholder, setKeyholder }) => {
  const { userName } = useGlobalContext();

  const types = {
    myself: {
      title: "Myself",
      description: "These Keys will be assigned to me",
    },
    another: {
      title: "For Multiple People",
      description: "Request Keys for Students, Faculty, and Staff members",
    },
  };

  useEffect(() => {
    if (
      keyholder.type === "myself" &&
      keyholder.users[0].userId !== userName?.uin
    ) {
      setKeyholder({
        type: "myself",
        users: [
          {
            userId: userName?.uin,
            firstName: userName?.firstName,
            lastName: userName?.lastName,
          },
        ],
      });
    }
  }, [keyholder, userName]);

  return (
    <div style={{ width: "100%" }}>
      <StyledContainer>
        <ButtonLarge
          selected={keyholder.type === "myself"}
          info={types.myself}
          onClick={(e) => {
            setKeyholder({
              type: "myself",
              users: [
                {
                  userId: userName?.uin,
                  firstName: userName?.firstName,
                  lastName: userName?.lastName,
                },
              ],
            });
          }}
          className={keyholder.type === "myself" ? "active" : ""}
        ></ButtonLarge>
        <ButtonLarge
          selected={keyholder.type === "another"}
          info={types.another}
          onClick={() => setKeyholder({ type: "another", users: [] })}
          className={keyholder.type === "another" ? "active" : ""}
        ></ButtonLarge>
      </StyledContainer>

      {keyholder.type === "myself" && (
        <KeyholdersSelected keyholder={keyholder} setKeyholder={setKeyholder} />
      )}

      {keyholder.type === "another" && (
        <>
          <h3 style={{ textAlign: "center" }}>Keyholders Selected</h3>

          <KeyholdersSelected
            keyholder={keyholder}
            setKeyholder={setKeyholder}
          />

          <StyledContainer2>
            <UsersList
              setKeyholder={setKeyholder}
              keyholder={keyholder}
            ></UsersList>
          </StyledContainer2>
          <div></div>
        </>
      )}
    </div>
  );
};

export default KeyRequestKeyholderStep;
