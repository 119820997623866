import React, { useState, useRef } from "react";
import Dropzone from "react-dropzone";
import * as XLSX from "xlsx";

import { withMsal } from "@azure/msal-react";

import axiosInstance from "hooks/Common/axiosClient";
import { DynamicTable } from "../DynamicTable/DynamicTable";

const formatDate = (date) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const formattedDate = new Date(date).toLocaleDateString("en-US", options);

  const month = formattedDate.split(" ")[0].slice(0, 3);
  const time = formattedDate.slice(formattedDate.indexOf(",") + 2);

  return `${month} ${formattedDate.slice(
    4,
    formattedDate.indexOf(",")
  )}, ${time}`;
};

const FileUpload = (props) => {
  const { backEndCall = null, defaultBatchSize = null } = props;

  let filesProcessed = useRef(false);
  let prevFile = useRef();
  const [apiDataTables, setApiDataTables] = useState([]);

  const [batchSize, setBatchSize] = useState(defaultBatchSize ?? 100000);
  const [files, setFiles] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isUploading, setIsUploadng] = useState(false);

  const loadApiTable = (fileName, sheetName, results, tbls) => {
    if (fileName === prevFile.current) fileName = null;
    else prevFile.current = fileName;

    setApiDataTables((current) => [
      ...current,
      {
        file: fileName,
        sheet: sheetName,
        message: results,
        tbls: tbls,
      },
    ]);
  };

  const postData = async (sheetData, file, dispFile) => {
    try {
      const response = await axiosInstance.post(
        backEndCall ?? "/api/requests/execKeysMassUpload",
        sheetData
      );

      const dataSets = response?.data;

      if (dataSets?.response) {
        const responseSet = dataSets?.response[0];
        const status = responseSet?.status ?? "";
        const errMessage = responseSet?.errors ?? "";
        const resultsArray = dataSets?.results;
        const rows = resultsArray?.length ?? 0;

        if (errMessage !== "") {
          const errText = responseSet?.error_text ?? "";
          setErrorMessages((oldArray) => [
            ...oldArray,
            `${file}${dispFile} -> ${errMessage} : ${errText}`,
          ]);
        }

        if (status === "") {
          setErrorMessages((oldArray) => [
            ...oldArray,
            `${file}${dispFile} -> ERROR(postData) File failed to process.`,
          ]);
        } else if (rows > 0) {
          loadApiTable(file, dispFile, resultsArray);
        } else {
          const results1Array = dataSets?.result1;
          const results2Array = dataSets?.result2;

          loadApiTable(file, dispFile, results1Array, results2Array);
        }
      } else {
        const status = response?.data?.statusCode ?? "";
        const message = response?.data?.message ?? "";

        const result = (status !== "" ? status + ": " : "") + message;

        const rsp =
          result !== "" ? result : JSON.stringify(response).substring(0, 250);
        let msg = "";
        if (rsp.indexOf("jwt expired") > 0) {
          msg = "(need to renew your token)";
        } else {
          msg = "(Try a smaller batch size)";
        }

        setErrorMessages((oldArray) => [
          ...oldArray,
          `${file}${dispFile} -> ERROR(postData) ${rsp} ${msg}`,
        ]);
      }
    } catch (error) {
      const errMsg = `${file}${dispFile} => ERROR (postData): ${error}`;
      console.error(errMsg);
      throw errMsg;
    }
  };

  const handleDrop = (acceptedFiles) => {
    if (filesProcessed.current) {
      filesProcessed.current = false;
      setErrorMessages([]);
    }

    const supportedTypes = [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    const newFiles = acceptedFiles
      .map((file) => {
        if (!supportedTypes.includes(file.type)) {
          setErrorMessages((oldArray) => [
            ...oldArray,
            `Unsupported file format: ${file.name}`,
          ]);
          return null;
        }

        return {
          name: file.name,
          size: file.size,
          lastModified: formatDate(file.lastModified),
          file,
        };
      })
      .filter(Boolean);

    setFiles([...files, ...newFiles]);
    clear();
  };

  const handleRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const postSingleThread = async (wrkFile, tempFileList) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      setIsUploadng(true);
      for (const sheetName of workbook.SheetNames) {
        const worksheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const lng = sheetData.length;
        let start = 1;

        while (start <= lng) {
          const end = Math.min(start + batchSize, lng);
          const sheetRef = [sheetData[0], ...sheetData.slice(start, end)];
          const dispFile =
            end > 0
              ? `(${sheetName}) - rows[${start} - ${end - 1}]`
              : `(${sheetName}) - is empty`;

          try {
            await postData(
              JSON.stringify(sheetRef),
              wrkFile.file.name ?? "Not Found",
              dispFile
            );

            setFiles(tempFileList);
            convertToJson(false); //We are not multithreading our pushes
          } catch (error) {
            setErrorMessages((oldArray) => [
              ...oldArray,
              `${wrkFile.file.name ?? "Not Found"}(${dispFile}) -> ${error}`,
            ]);
          }

          start += batchSize;
        }
      }

      setIsUploadng(false);
    };

    reader.readAsArrayBuffer(wrkFile.file);
  };

  const convertToJson = (keep, lockInput = false) => {
    if (keep) {
      setErrorMessages([]);
      if (batchSize < 500) {
        setBatchSize(500);
        setErrorMessages((oldArray) => [
          ...oldArray,
          "Minimum batch size of 500 is supported.",
        ]);
      }
    }
    filesProcessed.current = true;
    // Set temp to avoid premature state mutation and re-rendering
    const tempFileList = files; // aosar

    const popFile = () => {
      if (tempFileList.length > 0) {
        return tempFileList.shift();
      } else {
        return null;
      }
    };

    const wrkFile = popFile();
    if (wrkFile) {
      postSingleThread(wrkFile, tempFileList);
    }
  };

  const clearFiles = () => {
    setFiles([]);
    setErrorMessages([]);
    clear();
  };

  const clear = () => {
    files.current = [];
    setApiDataTables([]);
    prevFile.current = null;
  };

  const dropzoneStyle = {
    border: "2px dashed #777",
    borderRadius: "4px",
    padding: "30px",
    textAlign: "center",
    cursor: isUploading ? "default" : "pointer",
    backgroundColor: "#f0f0f0",
  };

  const handleInputChange = (event) => {
    const inputValue = parseInt(event.target.value);
    setBatchSize(inputValue);
  };

  return (
    <div>
      {isUploading ? <b>Processing Files</b> : <></>}
      <Dropzone onDrop={handleDrop} disabled={isUploading}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps({
              className: `dropzone ${isDragActive ? "active" : ""}`,
            })}
            style={dropzoneStyle}
          >
            <input
              {...getInputProps()}
              onChange={(event) => {
                const { files: acceptedFiles } = event.target;
                handleDrop(Array.from(acceptedFiles));
              }}
              accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            <p>
              Drag and drop Excel or CSV files here, or click to select files
            </p>
          </div>
        )}
      </Dropzone>
      <ul style={{ paddingLeft: "40px" }}>
        {errorMessages.map((errorMessage, index) => (
          <li key={index} style={{ alignItems: "left" }}>
            <div style={{ display: "flex" }}>
              <span style={{ marginRight: "10px" }}>{errorMessage}</span>
            </div>
          </li>
        ))}
      </ul>
      <ul style={{ paddingLeft: "20px", listStyleType: "decimal" }}>
        {files.map((file, index) => (
          <li key={index} style={{ alignItems: "center", marginBottom: "5px" }}>
            <div style={{ display: "flex" }}>
              <span style={{ marginRight: "10px" }}>
                {file.name} (<strong>Size:</strong> {file.size} bytes |&nbsp;
                <strong>Modified:</strong> {file.lastModified})
              </span>
              <button
                onClick={() => handleRemove(index)}
                style={{ marginLeft: "auto" }}
                disabled={isUploading}
              >
                Remove
              </button>
            </div>
          </li>
        ))}
      </ul>
      <button
        onClick={() => convertToJson(true)}
        disabled={files.length === 0 || isUploading}
      >
        Convert and Submit
      </button>
      &nbsp;
      <button onClick={clearFiles} disabled={files.length === 0 || isUploading}>
        Clear
      </button>
      &nbsp;/&nbsp;
      <label htmlFor="integer-MaxRowsToUpload">Enter upload batch size </label>
      <input
        type="number"
        id="integer-MaxRowsToUpload"
        value={batchSize}
        onChange={handleInputChange}
        disabled={isUploading}
      />
      <div>
        <br />
        {apiDataTables.map((tbl, index) => (
          <center key={index}>
            {apiDataTables[index].file ? (
              <>
                <h1>File: {apiDataTables[index].file}</h1>
              </>
            ) : (
              <></>
            )}
            <h2>Sheet: {apiDataTables[index].sheet}</h2>
            {(apiDataTables[index].message?.length ?? 0) > 0 ? (
              <DynamicTable data={apiDataTables[index].message} />
            ) : (
              <></>
            )}

            {(apiDataTables[index].tbls?.length ?? 0) > 0 ? (
              <>
                <h3>Failed Rows</h3>
                <DynamicTable data={apiDataTables[index].tbls} />
              </>
            ) : (
              <></>
            )}
          </center>
        ))}
      </div>
    </div>
  );
};

export default withMsal(FileUpload);
