import React, { useEffect, useRef, useState } from "react";
import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import { useQuery } from "react-query";
import { getRoomGeneral } from "hooks/Rooms/roomGeneral";
import Button from "components/Common/Button/Button";
import { StyledTable, TableBody } from "components/Common/Styles/TableStyles";
import { keys } from "@mui/system";

export function GetListTable({
  department, // if passed will set default department (unit) filter
  division, // if passed will set the dafualt division (department) (sometimes college but this in incorrect) filter
  building, // Building filter will add this later
  room, // Room if passed
  search,
  updateBuildings,
  updateCount,
  updateSearchCount,
  onRefresh,
  getHeader, // This function will generate the table header
  getRows, // This function will generate the table detail
  keysFilter = false, //Determine if Keys should be filtered (This will force using the keys database)
  keysFilterDatabase = false, //Use the keys database
  formatType = "all",
}) {
  const {
    roomCacheData,
    setRoomCacheData,
    buildingCacheData,
    setBuildingCacheData,
    buildingsSelected,
    setBuildingsSelected,
    roomsSelected,
    setRoomsSelected,
    keysSelected,
    setKeysSelected,
    keyCacheData,
    setKeyCacheData,
  } = useGlobalContext();

  const saveSrch = useRef();
  const saveResults = useRef("");
  const saveBuilding = useRef();
  const saveSearch = useRef();
  const rows = useRef();
  const offset = useRef(0);

  const val = [
    division,
    department,
    department === undefined ? building : undefined,
    room,
    keysFilter,
    keysFilterDatabase,
  ];

  const { isLoading, refetch, isError, error } = useQuery(
    ["rooms", val],
    () =>
      getRoomGeneral(
        formatType,
        "0",
        val[0],
        val[1],
        val[2],
        null,
        val[3],
        keysFilter,
        keysFilterDatabase
      ),
    {
      enabled: false,

      onSuccess: (data) => {
        const status = data?.status ?? -1;
        const result = data?.data?.results ?? "";

        if (formatType === "bl") {
          setBuildingCacheData({ ...buildingCacheData, [val]: result });
          if (!buildingCacheData[val] && status === 200) {
            setBuildingCacheData({ ...buildingCacheData, [val]: result });
            if (typeof onRefresh === "function") onRefresh();
          }
        } else if (formatType === "key") {
          setKeyCacheData({ ...keyCacheData, [val]: result });
          if (!keyCacheData[val] && status === 200) {
            setKeyCacheData({ ...keyCacheData, [val]: result });
            if (typeof onRefresh === "function") onRefresh();
          }
        } else {
          setRoomCacheData({ ...roomCacheData, [val]: result });
          if (!roomCacheData[val] && status === 200) {
            setRoomCacheData({ ...roomCacheData, [val]: result });
            if (typeof onRefresh === "function") onRefresh();
          }
        }
      },
      fetchPolicy: "cache-first",
    }
  );

  let results = null;
  if (formatType === "bl") {
    results = buildingCacheData[val];
  } else if (formatType === "key") {
    results = keyCacheData[val];
  } else {
    results = roomCacheData[val];
  }

  useEffect(() => {
    if (
      !isLoading &
      (val[0] !== undefined ||
        val[1] !== undefined ||
        (val[2] !== undefined && val[2] !== "") ||
        (val[3] !== undefined && val[3] !== ""))
    ) {
      if (formatType === "bl" && !buildingCacheData[val]) {
        refetch();
      } else if (formatType === "key" && !keyCacheData[val]) {
        refetch();
      } else if (!roomCacheData[val] && formatType !== "bl") {
        refetch();
      }
    }

    resetFilters();

    try {
      if (results) {
        if (typeof updateCount === "function") {
          updateCount(offset.current);
        }

        if (typeof updateBuildings === "function") updateBuildings(results);

        if (typeof updateSearchCount === "function")
          updateSearchCount(saveSrch.current ?? 0);
      }
    } catch (error) {
      console.error("ERROR (getListTable): " + error.message);
    }

    // eslint-disable-next-line
  }, [isLoading, division, department, building, room]);

  if (typeof getRows === "function") {
    getRows(
      rows,
      isLoading,
      isError,
      error,
      results,
      saveResults,
      saveBuilding,
      building,
      saveSearch,
      search,
      saveSrch,
      roomsSelected,
      setRoomsSelected,
      buildingsSelected,
      setBuildingsSelected,
      keysSelected,
      setKeysSelected,
      offset
    );
  }

  const tableHeader = typeof getHeader === "function" ? getHeader() : null;

  const resetFilters = () => {
    setCurrentPageNumber(0);
    setDataToDisplay([]);
  };

  //paginate
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(true);
  const TOTAL_VALUES_PER_PAGE = 10;

  //if there are not more rows, disable next button
  useEffect(() => {
    if (rows.current && rows.current.length) {
      if (currentPageNumber === 1) {
        setPrevDisabled(true);
      } else {
        setPrevDisabled(false);
      }
      if (currentPageNumber >= rows.current.length / TOTAL_VALUES_PER_PAGE) {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }
    }
  }, [rows.current, currentPageNumber]);

  const goOnPrevPage = () => {
    if (currentPageNumber === 1) return;
    setCurrentPageNumber((prev) => prev - 1);
  };
  const goOnNextPage = () => {
    if (currentPageNumber === rows.current.length / TOTAL_VALUES_PER_PAGE)
      return;
    setCurrentPageNumber((prev) => prev + 1);
  };

  useEffect(() => {
    const start = (currentPageNumber - 1) * TOTAL_VALUES_PER_PAGE;
    const end = currentPageNumber * TOTAL_VALUES_PER_PAGE;

    if (rows.current && rows.current.length) {
      // check if rooms selected are in rows.current.slice(start, end), remove them if so

      if (formatType === "all") {
        let roomsSetToDisplay = rows.current.slice(start, end);

        const newRoomsSelected = roomsSetToDisplay.filter(
          (room) =>
            !roomsSelected.some(
              (selected) =>
                selected.rm_id +
                  selected.bl_id +
                  selected.fl_id +
                  selected.dp_id ===
                room.key
            )
        );

        setDataToDisplay(newRoomsSelected);
      } else if (formatType === "bl") {
        let buildingsSetToDisplay = rows.current.slice(start, end);

        const newBuildingsSelected = buildingsSetToDisplay.filter(
          (building) =>
            !buildingsSelected.some(
              (selected) => selected.bl_id === building.bl_id
            )
        );

        setDataToDisplay(newBuildingsSelected);
      } else if (formatType === "key") {
        let keysSetToDisplay = rows.current.slice(start, end);

        const newKeysSelected = keysSetToDisplay.filter(
          (key) => !keysSelected.some((selected) => selected.id === key.key)
        );

        setDataToDisplay(newKeysSelected);
      }
    }
  }, [
    currentPageNumber,
    keysSelected,
    buildingsSelected,
    roomsSelected,
    isLoading,
    division,
    department,
    building,
    search,
  ]);

  useEffect(() => {
    if (typeof updateSearchCount === "function")
      updateSearchCount(saveSrch.current ?? 0);

    // eslint-disable-next-line
  }, [saveSrch.current]);

  //function to trigger the first re-render
  if (currentPageNumber === 0 && rows.current && rows.current.length) {
    setCurrentPageNumber(1);
  }

  return !!rows.current &&
    !!rows.current?.length &&
    dataToDisplay &&
    dataToDisplay?.length ? (
    <>
      <StyledTable>
        {tableHeader}
        <TableBody>{dataToDisplay}</TableBody>
      </StyledTable>
      <br />
      <Button onClick={goOnPrevPage} disabled={prevDisabled} label="Prev" />
      <Button onClick={goOnNextPage} disabled={nextDisabled} label="Next" />
    </>
  ) : (
    <>
      <StyledTable>{tableHeader}</StyledTable>
      <div style={{ width: "100%", textAlign: "center" }}>
        {isLoading ? (
          <p>Loading...</p>
        ) : isError ? (
          <p>{error.message}</p>
        ) : (
          <p>No data found</p>
        )}
      </div>
    </>
  );
}
