import React, { useState, useRef } from "react";
import {
  StyledContainer,
  StyledContainer2,
  KeysNav,
  InnerNav,
} from "./AccessStep.style";
import Select from "components/Common/Select/Select";
import { GetUnitsDropDown } from "components/Context/getUnitsDropDown/getUnitsDropDown";
import { GetBuildingsDropDown } from "components/Context/getBuildingDropDown/getBuildingDropDown";
import { GetListTable } from "components/Context/getListTable/getListTable";
import RoomsSelected from "../RoomsSelected/RoomsSelected";
import ButtonLarge from "components/Common/ButtonLarge/ButtonLarge";

import {
  getSelectableRoomRows,
  getSelectableRoomHeader,
} from "../../../../hooks/Rooms/roomsTableFunctions";

import {
  getSelectableBuildingRows,
  getSelectableBuildingHeader,
} from "../../../../hooks/Buildings/buildingsTableFunctions";

import {
  getSelectableKeyRows,
  getSelectableKeyHeader,
} from "../../../../hooks/Key/keysTableFunctions";

import BuildingsSelected from "components/RequestAKey/BuildingsSelected/BuildingsSelected";
import KeysSelectedTable from "components/RequestAKey/KeysSelected/KeysSelectedTable";

const KeyRequestAccessStep = (props) => {
  const { requestType, setRequestType } = props;
  const [search, setSearch] = useState("");
  const [building, setBuilding] = useState();
  const [department, setDepartment] = useState();
  const [buildings, setBuildings] = useState([]);
  const [count, setCount] = useState({
    rooms: 0,
    buildings: 0,
    results: 0,
  });
  const [searchResults, setSearchResults] = useState();
  const saveBuilding = useRef("");

  const handleSelectDepartment = (e) => {
    if (e === "") {
      updateCount({
        rooms: 0,
        buildings: 0,
        results: 0,
      });
      setDepartment();
      setBuildings([]);
    } else setDepartment(e);
  };

  const handleSelectBuilding = (e) => {
    setBuilding(e);
    saveBuilding.current = e;
  };

  const handleSelectAllBuilding = (e) => {
    setBuilding(e);
    setDepartment();
    saveBuilding.current = e;
  };

  function filterBuildings(data) {
    let buildings = data
      .map(({ bl_id, bl_name }) => ({
        id: bl_id,
        name: bl_name + " (" + bl_id + ")",
      }))
      .filter((e) => {
        return (
          e.name !== null && e.id !== null && (e.name !== null || e.id !== null)
        );
      })
      .sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

    let buildingList = [
      ...new Map(buildings.map((item) => [item["id"], item])).values(),
    ];

    if (
      saveBuilding.current !== "" &&
      buildingList.some((item) => item.id.includes(building))
    )
      setBuilding(saveBuilding.current);

    setBuildings(buildingList);

    updateCount({
      rooms: buildings.length,
      buildings: buildingList.length,
    });
  }

  const updateSearchCount = (srch) => {
    setSearchResults(srch);
  };

  function updateCount(count) {
    setCount(count);
  }

  return (
    <div>
      <StyledContainer2>
        <ButtonLarge
          selected={requestType === "room"}
          info={{
            title: "Room",
            description: "Select a room to gain access to",
          }}
          onClick={(e) => {
            setRequestType("room");
            handleSelectDepartment("");
          }}
          className={requestType === "room" ? "active" : ""}
        ></ButtonLarge>
        <ButtonLarge
          selected={requestType === "building"}
          info={{
            title: "Building",
            description: "Select a building to gain access to exterior doors",
          }}
          onClick={(e) => {
            setRequestType("building");
            handleSelectDepartment("");
          }}
          className={requestType === "building" ? "active" : ""}
        ></ButtonLarge>
        <ButtonLarge
          selected={requestType === "key"}
          info={{
            title: "Labeled Key",
            description:
              "Select keys directly by searching for keys by department labels",
          }}
          onClick={(e) => {
            setRequestType("key");
            handleSelectDepartment("");
          }}
          className={requestType === "key" ? "active" : ""}
        ></ButtonLarge>
      </StyledContainer2>

      {requestType === "room" && (
        <StyledContainer>
          <h3>Rooms Selected</h3> <RoomsSelected />
          <h3>Search Rooms</h3>
          <KeysNav>
            <InnerNav>
              <GetUnitsDropDown onSelectItem={handleSelectDepartment} />
              {department !== undefined ? (
                <Select
                  label="Building"
                  optionsheader="-- Select a Building --"
                  options={buildings}
                  onChange={(e) => handleSelectBuilding(e)}
                  defaultValue={saveBuilding.current}
                />
              ) : (
                <GetBuildingsDropDown
                  onSelectItem={(e) => handleSelectAllBuilding(e)}
                  defaultValue={saveBuilding.current}
                />
              )}

              <div>
                <label style={{ fontWeight: "700" }}>Search</label>
                <br />
                <input
                  id="search"
                  label="Search"
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  style={{
                    height: "2.3rem",
                    border: "1px solid rgb(179, 179, 179)",
                  }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </InnerNav>
          </KeysNav>
          <InnerNav>
            <GetListTable
              department={department}
              building={building}
              search={search}
              updateCount={(count) => updateCount(count)}
              updateBuildings={(data) => filterBuildings(data)}
              updateSearchCount={(srch) => updateSearchCount(srch)}
              getHeader={getSelectableRoomHeader}
              getRows={getSelectableRoomRows}
            />
          </InnerNav>
        </StyledContainer>
      )}
      {requestType === "building" && (
        <StyledContainer>
          <h3>Buildings Selected</h3>
          <BuildingsSelected />
          <h3>Search Buildings</h3>
          <KeysNav>
            <InnerNav>
              <GetUnitsDropDown onSelectItem={handleSelectDepartment} />
              <div>
                <label style={{ fontWeight: "700" }}>
                  Search by Name or Address
                </label>
                <br />
                <input
                  id="search"
                  label="Search"
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  style={{
                    height: "2.3rem",
                    border: "1px solid rgb(179, 179, 179)",
                  }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </InnerNav>
          </KeysNav>
          <InnerNav>
            <GetListTable
              department={department}
              search={search}
              updateCount={(count) => updateCount(count)}
              updateBuildings={(data) => filterBuildings(data)}
              updateSearchCount={(srch) => updateSearchCount(srch)}
              getHeader={getSelectableBuildingHeader}
              getRows={getSelectableBuildingRows}
              formatType="bl"
            />
          </InnerNav>
        </StyledContainer>
      )}
      {requestType === "key" && (
        <StyledContainer>
          <h3>Keys Selected</h3>
          <KeysSelectedTable />
          <h3>Search Keys</h3>
          <KeysNav>
            <InnerNav>
              <GetUnitsDropDown onSelectItem={handleSelectDepartment} />
              <div>
                <label style={{ fontWeight: "700" }}>Search by label</label>
                <br />
                <input
                  id="search"
                  label="Search"
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  style={{
                    height: "2.3rem",
                    border: "1px solid rgb(179, 179, 179)",
                  }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </InnerNav>
          </KeysNav>
          <InnerNav>
            <GetListTable
              department={department}
              search={search}
              updateCount={(count) => updateCount(count)}
              updateBuildings={(data) => filterBuildings(data)}
              updateSearchCount={(srch) => updateSearchCount(srch)}
              getHeader={getSelectableKeyHeader}
              getRows={getSelectableKeyRows}
              keysFilterDatabase={true}
              formatType="key"
            />
          </InnerNav>

          {/* getRoomGeneral Call, pass in label and put (key) for detail */}
          {/* label "instrument room" or "test1" */}
        </StyledContainer>
      )}
    </div>
  );
};

export default KeyRequestAccessStep;
