import React, { useEffect } from "react";
import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import { useQuery } from "react-query";
import Select from "components/Common/Select/Select";
import { getRoomGeneral } from "hooks/Rooms/roomGeneral";

export function GetUnitsDropDown({
  onSelectItem, //function that gets the selected value
  department, // if passed will set default department (unit) filter
  division, // if passed will set the dafault division (department) (sometimes college but this in incorrect) filter
  setFirstValue, //set the default value
  onRefresh, //function called after data is loaded
  totalCountCallback, //total items in dropdown
  defaultOption, //Set the default based on the text provided
  defaultValue, //set the default
  keysFilter = false, //Determine if Keys should be filtered (This will force using the keys database)
  keysFilterDatabase = false, //Use the keys database
}) {
  const { unitCacheData, setUnitCacheData } = useGlobalContext();
  const val = [division, department, keysFilter, keysFilterDatabase];

  const handleSelectUnit = (item) => {
    if (typeof onSelectItem === "function") onSelectItem(item);
  };

  const { data, isLoading, refetch, isError, error } = useQuery(
    ["Unit", val],
    () =>
      getRoomGeneral(
        "dp",
        "3600",
        val[0],
        val[1],
        null,
        null,
        null,
        keysFilter,
        keysFilterDatabase
      ),
    {
      select: (data) => {
        try {
          let units = data?.data.results
            .map(({ dp_id, dp_name }) => ({
              id: dp_id,
              name: dp_name + " (" + dp_id + ")",
            }))
            .sort((a, b) => {
              let fa = a?.name.toLowerCase(),
                fb = b?.name.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            });
          return units;
        } catch (error) {
          console.error("ERROR (getUnitDropDown): " + error.message);
          if (!unitCacheData[val])
            setUnitCacheData({ ...unitCacheData, [val]: data });
        }
        return null;
      },
      enabled: false,
      onSuccess: (data) => {
        if (!unitCacheData[val]) {
          //console.log("DEPARTMENT: " + data?.length.toString());
          setUnitCacheData({ ...unitCacheData, [val]: data });
          if (typeof onRefresh === "function") onRefresh();
        }
      },
      fetchPolicy: "cache-first",
    }
  );

  useEffect(() => {
    if (!isLoading && !unitCacheData[val]) {
      refetch();
    }

    try {
      if (unitCacheData[val] && typeof totalCountCallback === "function")
        totalCountCallback(unitCacheData[val].length ?? 0);
    } catch (error) {
      console.error("ERROR (getUnitsDropDown): " + error.message);
    }
    // eslint-disable-next-line
  }, [isLoading, division, department]);

  return (
    <>
      {isLoading ? (
        <Select label="Unit" optionsheader="-- Loading --" />
      ) : isError ? (
        <Select label="Unit" optionsheader={error.message} />
      ) : (
        <Select
          label="Unit"
          defaultValue={defaultValue}
          defaultOption={defaultOption}
          optionsheader={setFirstValue ? "" : "-- Select a Unit --"}
          options={unitCacheData[val] ?? data}
          onChange={(e) => handleSelectUnit(e)}
        />
      )}
    </>
  );
}
