import { useQuery } from "react-query";
import { getUserICard } from "hooks/User/userData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Container } from "./UserImage.style";

const Image = (props) => {
  const { user = "", width = "50px" } = props;

  const { isLoading, isError, error, data } = useQuery(
    ["image", user],
    () => getUserICard(user),
    {
      onSuccess: (data) => {},
      refetchOnWindowFocus: false,
    }
  );

  let rows;
  if (isLoading) {
    rows = (
      <img
        src={process.env.PUBLIC_URL + "/data/images.jpg"}
        alt=""
        style={{ width: "40px", height: "40px", verticalAlignz: "bottom" }}
      />
    );
  } else if (isError) {
    rows = error.message;
  } else {
    let rst = data?.data?.results;

    let img = rst?.[0]?.UINData?.[0]?.IDPhoto?.[0]?.PhotoJPGBase64;

    rows = rst?.map((image, index) => (
      <Container>
        {image?.UINData?.[0].IDPhoto?.[0]?.PhotoJPGBase64 ? (
          <img
            key={index}
            src={
              (typeof img === "string" || img instanceof String) &&
              img.length > 1
                ? `data:image/jpg;base64,${image?.UINData?.[0].IDPhoto?.[0]?.PhotoJPGBase64}`
                : process.env.PUBLIC_URL + "/data/images.jpg"
            }
            alt={`${image.UINData[0].Person[0].FirstName} ${image.UINData[0].Person[0].LastName}`}
            style={{ width: `${width}` }}
          />
        ) : (
          <>
            <FontAwesomeIcon
              style={{
                color: "#13294B",
                height: "2.5rem",
                marginBottom: "1rem",
              }}
              alt="no image found"
              icon={faUser}
            />
            <p>No Image Found</p>
          </>
        )}
      </Container>
    ));
  }

  return rows;
};
export default Image;
