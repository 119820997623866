import { useState, useRef, useEffect } from "react";
import Button from "../Button/Button";
import { StyledTable, FlexSpace } from "./Table.style";
import styled, { css } from "styled-components";
import { Modal } from "components/Common/Modal/Modal";
import Input from "../Input/Input";
import LocalDetail from "components/Keys/LocalDetails/LocalDetail";

const Table = (props) => {
  const {
    title = "",
    headers = {},
    data = {},
    actions = "",
    handleSave = null,
  } = props;

  const [action, setAction] = useState(null);
  const [isActive, setActive] = useState(false);
  const [id, setID] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const formData = useRef({}); //A copy the selected row

  const save = handleSave ?? null;

  const handleDelete = (n) => {
    setActive(true);
    setAction("delete");
    setID(n);
  };

  const handleEdit = (n) => {
    formData.current = structuredClone(data?.[n]);

    setAction("edit");
    setID(n);
    setShowModal(true);
  };

  const handleView = (n) => {
    setAction("view");
    setID(n);
    setShowModal(true);
  };

  const handleNew = () => {
    formData.current = {};

    setAction("new");
    setID(null);
    setShowModal(true);
  };

  const handleCancel = () => {
    setActive(false);
    setAction(null);
    setID(null);
  };

  const handleConfirm = () => {
    const row = data?.[id] ?? null;

    if (typeof save === "function" && row !== null) {
      save(row, action, id, data);
    }

    setActive(false);
    setAction(null);
    setID(null);
  };

  const saveData = () => {
    const row = formData.current;
    let msg = "";

    // load defaults if not already overridden
    for (const elm of headers.filter((x) => filter(x, action, false, true))) {
      const fld = elm?.field ?? "";
      if (!row?.[fld]) {
        row[fld] = elm?.defaults;
      }
    }

    // Validate that "required" fields are defined
    for (const elm of headers.filter((x) => filter(x, action, true))) {
      const fld = elm?.field ?? "";

      const originalValue = data?.[id]?.[fld] ?? null;
      const newValue = row?.[fld] ?? null;

      if (fld != "") {
        if (
          newValue == "" ||
          (newValue == null && (originalValue == null || originalValue == ""))
        ) {
          const lbl = elm?.label ?? "value";

          msg += lbl + ": Must be defined\n";
        }
      }
    }

    if (msg != "") {
      alert(msg.slice(0, -1));
      return;
    }

    if (typeof save === "function" && row !== null) {
      save(row, action, id, data);
    }

    setAction(null);
    setID(null);
    setShowModal(false);
  };

  const filter = (data, incl = "table", req = false, dft = false) => {
    const include = data?.include ?? null;

    if (incl === "all" || include === null || include.includes(incl)) {
      if (req) {
        const resp = data?.required ?? false;

        if (!resp) return false;
      }

      if (dft) {
        return data?.defaults ?? false;
      }

      return true;
    }

    return false;
  };

  let cntr = 0;
  const val =
    (actions.includes("edit") ? 5 : 0) +
    (actions.includes("delete") ? 5 : 0) +
    (actions.includes("view") ? 5 : 0);

  const onLocalChange = (item, newValue) => {
    if (formData?.current ?? null) {
      formData.current[item] = newValue;
    }
  };

  return (
    <div>
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          heading={action}
          saveButton={
            ["new", "edit"].includes(action) && typeof save === "function"
          }
          saveFunction={saveData}
          content={
            <>
              <h2>
                <center>{title}</center>
              </h2>
              <span
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto auto auto",
                }}
              >
                {headers
                  .filter((x) => filter(x, action))
                  .map((header) => (
                    <LocalDetail
                      key={cntr++}
                      value={
                        id !== null
                          ? data?.[id]?.[header?.field] ?? ""
                          : header?.defaults ?? ""
                      }
                      label={header?.label ?? "No Label"}
                      item={header?.field}
                      type={header?.type ?? "text"}
                      readonly={header?.readonly ?? true}
                      maxLength={header?.maxlength ?? 0}
                      onChange={onLocalChange}
                      row={header?.row ?? "span 1"}
                      column={header?.column ?? "span 1"}
                    />
                  ))}
              </span>
            </>
          }
        />
      )}
      <h2>
        <center>{title}</center>
      </h2>
      {actions.includes("new") && (
        <FlexSpace>
          <Button label="New" onClick={handleNew} />
        </FlexSpace>
      )}
      <StyledTable>
        <thead>
          <tr>
            <th width="1%">n</th>
            {headers
              .filter((x) => filter(x))
              .map((header) => (
                <th key={cntr++}>{header.label}</th>
              ))}
            {val > 0 ? <th width="10%">Actions</th> : <></>}
          </tr>
        </thead>
        <tbody>
          {data?.map((record, index) => (
            <StyledTr
              key={cntr++}
              style={{
                backgroundColor: index === id && isActive ? "#f2f2f2" : "",
              }}
            >
              <td width="5%">{index + 1}</td>
              {headers
                .filter((x) => filter(x))
                .map((header, index) => (
                  <td key={index}>{record[header.field]}</td>
                ))}
              {val > 0 ? (
                <td width={val + "%"}>
                  <div
                    style={{
                      clear: "both",
                      padding: "2px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {actions.includes("delete") ? (
                      index === id && action === "delete" ? (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Button
                            onClick={() => handleConfirm()}
                            label="Confirm"
                          >
                            Confirm
                          </Button>
                          <Button onClick={() => handleCancel()} label="Cancel">
                            Cancel
                          </Button>
                        </div>
                      ) : (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Button
                            onClick={() => handleDelete(index)}
                            label="Delete"
                          >
                            Delete
                          </Button>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                    {actions.includes("edit") ? (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Button onClick={() => handleEdit(index)} label="Edit">
                          Edit
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                    {actions.includes("view") ? (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Button onClick={() => handleView(index)} label="View">
                          View
                        </Button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </td>
              ) : (
                <></>
              )}
            </StyledTr>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};

export default Table;

//These need to be updated with buttom components
const StyledTr = styled.tr`

background-color: props?.action==='delete'?'#F2F2F2':'' ;


    ${(props) => props?.action && css``}

`;
const StyledActionLink = styled.a`
  float: right;
  border-radius: 4px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 2px;
  cursor: pointer;
  ${(props) =>
    props?.primary &&
    css`
      color: #13294b;
    `}

  &:hover {
    color: #13294b;
  }
`;
