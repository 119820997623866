import { Link } from "react-router-dom";
import { StyledBigButton } from "./BigButton.styles";

export const BigButton = (props) => {
  return (
    <Link to={props.path}>
      <StyledBigButton>{props.label}</StyledBigButton>
    </Link>
  );
};
