import React from "react";
import { useEffect, useState } from "react";
import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import {
  getRemovableKeyHeader,
  getRemoveableKeyRows,
} from "hooks/Key/keysTableFunctions";
import { StyledTable } from "components/Common/Styles/TableStyles";

const KeysSelectedTable = () => {
  const { keysSelected, setKeysSelected } = useGlobalContext();

  const [rows, setRows] = useState(null);
  const [header, setHeader] = useState(null);

  useEffect(() => {
    setRows(getRemoveableKeyRows(keysSelected, setKeysSelected));
    setHeader(getRemovableKeyHeader());
  }, [keysSelected]);

  return (
    <>
      {keysSelected.length === 0 ? (
        <p
          style={{
            textAlign: "center",
            margin: "0 auto",
            marginBottom: "1rem",
            color: "var(--illiniDarkBlue)",
          }}
        >
          No keys selected
        </p>
      ) : (
        <StyledTable>
          {header}
          {rows}
        </StyledTable>
      )}
    </>
  );
};

export default KeysSelectedTable;
