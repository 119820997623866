import Router from "router";
import { ReactQueryDevtools } from "react-query/devtools";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { MsalProvider } from "@azure/msal-react";
import { GlobalProvider } from "components/Common/GlobalContext/GlobalContext";

import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

function App({ msalInstance }) {
  const isAuthenticated = useIsAuthenticated();

  return (
    <MsalProvider instance={msalInstance}>
      <GlobalProvider>
        <QueryClientProvider client={queryClient}>
          <Router isAuthenticated={isAuthenticated} instance={useMsal()} />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </GlobalProvider>
    </MsalProvider>
  );
}

export default App;
